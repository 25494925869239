import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getUserProfile() {
      this.$store.commit('setProgressVal', 1);
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/profile`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => {
          if (data) {
            localStorage.setItem('profile', JSON.stringify(data.results));
          }
          return data.results;
        })
        .catch((error) => error);
    },
    // Метод не работает
    // getUserID() {
    //   return this.baseAxios({
    //     method: 'get',
    //     url: `${this.$store.getters.apiBasePath}/profile/check`,
    //   })
    //     .then((data) => data)
    //     .catch((error) => error);
    // },
  },
};
