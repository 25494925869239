<template>
<div class="notification-list">
  <div class="row"><div
      v-for="notification in listNotifications"
      :key="notification.id"
      class="col-12"
    >
      <notification-card
        :notification="notification"
      />
    </div>
  </div>
</div>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import notification from '@/components/mixins/methodsNotification';

export default {
  name: 'ListNotification',
  props: {
    locale: {
      type: String,
    },
  },
  data() {
    return {
      listNotifications: [],
    };
  },
  mixins: [appBaseNav, notification],
  watch: {
    locale() {
      this.loadPage();
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    loadPage() {
      this.$store.commit('setPageTitle', this.$t('notificationList.header_title'));
      this.$emit('loading', true);
      Promise.all([
        this.getNotificationList(),
      ])
        .then(([
          { results },
        ]) => {
          this.listNotifications = [...results];
        })
        .finally(() => {
          this.$emit('loading', false);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss">
.notification-list {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 20px;

  & > .row {
    margin: 0 -10px;
    row-gap: 20px;
  }
}
</style>
