import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],

  methods: {
    getEventsList() {
      this.$store.commit('setProgressVal', 1);
      const cachedData = localStorage.getItem('event-list');
      return this.baseAxios({
        method: 'get',
        // url: `${this.$store.getters.apiBasePath}/event/page/${page}`,
        url: `${this.$store.getters.apiPath}/${this.$store.getters.getLang}/event-list.json`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => {
          if (data) {
            localStorage.setItem('event-list', JSON.stringify(data));
            return data;
          }
          console.log('что-то пошло не так');
          return null;
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('setProgressVal', 100);
          return cachedData ? JSON.parse(cachedData) : null;
        });
    },
  },
};
