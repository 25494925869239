<template>
  <aside>
    <div class="aside__inner">
      <nav class="menu-grid">
        <router-link to="/about">
          <div class="icon__wrapper">
            <i class="exu-home"></i>
          </div>
          <span>{{ $t('mainMenu.about') }}</span>
        </router-link>
        <router-link :to="{ name: 'staticPage', params: { alias: 'regulations' } }">
          <div class="icon__wrapper">
            <i class="exu-document"></i>
          </div>
          <span>{{ $t('mainMenu.regulations') }}</span>
        </router-link>
        <router-link :to="{ name: 'staticPage', params: { alias: 'faq' } }">
          <div class="icon__wrapper">
            <i class="exu-doc-question-1"></i>
          </div>
          <span>{{ $t('mainMenu.faq') }}</span>
        </router-link>
        <router-link :to="{ name: 'program' }">
          <div class="icon__wrapper">
            <i class="exu-calendare"></i>
          </div>
          <span>{{ $t('mainMenu.program') }}</span>
        </router-link>
        <router-link to="/speakers/1">
          <div class="icon__wrapper">
            <i class="exu-mic"></i>
          </div>
          <span>{{ $t('mainMenu.speakers') }}</span>
        </router-link>
        <router-link :to="{
          name: 'staticPage', hash: '#faq-speaker-question', params: { alias: 'faq' }
        }" class="no-active-light">
          <div class="icon__wrapper">
            <i class="exu-question"></i>
          </div>
          <span>{{ $t('mainMenu.faq-question') }}</span>
        </router-link>
        <router-link to="/participants/1">
          <div class="icon__wrapper">
            <i class="exu-users"></i>
          </div>
          <span>{{ $t('mainMenu.participants') }}</span>
        </router-link>
        <router-link to="/partners/1">
          <div class="icon__wrapper">
            <i class="exu-award-3"></i>
          </div>
          <span>{{ $t('mainMenu.partners') }}</span>
        </router-link>
        <router-link :to="{ name: 'staticPage', params: { alias: 'map' } }">
          <div class="icon__wrapper">
            <i class="exu-map"></i>
          </div>
          <span>{{ $t('mainMenu.layout') }}</span>
        </router-link>
        <router-link to="/chat">
          <div class="icon__wrapper">
            <i class="exu-mail-2"></i>
            <span
              v-if="newChatUnread"
              class="bubble"
            >
              {{ newChatUnread }}
            </span>
          </div>
          <span>{{ $t('mainMenu.messages') }}</span>
        </router-link>
        <div class="menu-point"></div>
        <router-link to="/notifications/1">
          <div class="icon__wrapper">
            <i class="exu-annotation-alert"></i>
            <span
              v-if="newNotification"
              class="bubble"
            >
              {{ newNotification }}
            </span>
          </div>
          <span>{{ $t('mainMenu.notification') }}</span>
        </router-link>
      </nav>
      <div class="bottom-menu__wrapper">
        <nav class="bottom-menu">
          <button
            class="menu-button"
            @click="changeLocale"
          >
            <div class="icon__wrapper">
              <strong style="font-size: 18px;">{{ langIcon }}</strong>
            </div>
            <span>{{ $t('mainMenu.lang') }}</span>
          </button>
          <button
            v-if="updatePwaExist"
            @click="handleUpdatePwa"
            class="menu-button"
          >
            <div class="icon__wrapper">
              <i class="exu-refresh-cw-03"/>
            </div>
            <span>{{ $t('mainMenu.update') }}</span>
          </button>
          <div
            v-else
            class="app_version"
          >
            v 1.4.1
          </div>
          <router-link :to="{ name: 'userProfile' }">
              <div class="icon__wrapper">
                <i class="exu-user"></i>
              </div>
              <span>{{ $t('mainMenu.profile') }}</span>
          </router-link>
        </nav>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'MainAsideMenu',
  props: {
    locale: {
      type: String,
      default: 'ru',
    },
    updatePwaExist: {
      type: Boolean,
      default: false,
    },
  },
  created() {
  },
  mounted() {
  },
  computed: {
    langIcon() {
      return this.locale === 'ru' ? 'Eng' : 'Рус';
    },
    newNotification() {
      return this.$store.getters.getNewNotificationCount < 100 ? this.$store.getters.getNewNotificationCount : '99+';
    },
    newChatUnread() {
      return this.$store.getters.getUnreadChatCount < 100 ? this.$store.getters.getUnreadChatCount : '99+';
    },
  },
  methods: {
    changeLocale() {
      this.$emit('switch-locale', this.locale === 'ru' ? 'en' : 'ru');
    },
    handleUpdatePwa() {
      this.$emit('update-pwa', !this.updatePWAExist);
    },
  },
};
</script>

<style lang="scss">
aside {
  grid-area: aside;
  display: flex;
  justify-content: end;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  transition: opacity .5s;
  z-index: 200;

  .aside__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #FFF;
    /* box-shadow: 0px -10px 10px 10px rgba(0, 0, 0, .25); */
    transition: transform .5s;
    border-left: 1px solid #e0e0e0;

    .menu-grid {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 25px;

      @media (min-width: 350px) {
        & {
          width: 350px;
          margin: auto;
          gap: 30px 50px;
        }
      }
    }
    .bottom-menu {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;

      @media (min-width: 350px) {
        & {
          width: 350px;
          margin: auto;
          gap: 30px 50px;
        }
      }

      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: 100px;
        width: 100%;
        border-top: 1px solid #E0E0E0;
        padding: 0;
      }
    }

    a, .menu-button, .menu-point {
      display: flex;
      flex: 0 1 60px;
      max-width: 60px;
      height: 78px;
      flex-direction: column;
      align-items: center;
      color: #356899;
      text-decoration: none;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      border: none;
      background: transparent;

      .icon__wrapper {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border: 2px solid #356899;
        border-radius: 50%;
        & > [class^="exu-"], & > [class*=" exu-"] {
          font-size: 27px;
        }
      }
      &.router-link-exact-active:not(.no-active-light), &.router-link-active:not(.no-active-light) {
        color: #356899;
        .icon__wrapper {
          background: #356899;
          & > [class^="exu-"], & > [class*=" exu-"] {
            color: #F0F0F0;
          }
        }
      }

      .bubble {
        position: absolute;
        bottom: -2px;
        right: -2px;
        display: flex;
        width: 30px;
        height: 30px;
        padding: 3px;
        font-size: .8rem;
        line-height: 1rem;
        background: #FC1010;
        border-radius: 50%;
        color: #FEFEFE;
        align-items: center;
        justify-content: center;
      }
    }
    .menu-point {
      color: #95969D;
      .icon__wrapper {
        border-color: #95969D;
      }
    }
    .app_version {
      display: flex;
      align-items: end;
      color: #95969D;
    }
  }
}
</style>
