<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
<div class="droplist__wrapper">
  <div :class="droplistClass">
    <div class="droplist--status-line">
      <div
        @click="issetOptions ? isOpenSection = !isOpenSection : null"
        class="droplist--selected"
      >
        {{ selectedName }}
      </div>
      <div
        v-if="issetOptions"
        class="droplist--controller"
      >
        <button
          class="droplist-button"
          @click="isOpenSection = !isOpenSection"
        >
          <i
            v-if="!isOpenSection"
            class="exu-chevron-down"
          />
          <i
            v-else
            class="exu-chevron-up"
          />
        </button>
      </div>
    </div>
    <div
      v-if="issetOptions"
      class="droplist--selection"
    >
      <ul>
        <li
          @click="selectUpdate(null)"
          :class="{ 'selected': selected === null }"
        >{{ defaultName }}</li>
        <li
          v-for="option in options"
          :key="option.id"
          :class="{ 'selected': selected === option.id }"
          @click="selectUpdate(option.id)"
        >
          <div class="li-row">{{ option.name }}</div>
          <div
            v-if="option.subtext"
            class="li-row"
          >
            <span class="sub-text">
              {{ option.subtext }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'DropList',
  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
    defaultName: {
      type: String,
      default: '',
    },
    selected: null,
  },
  data() {
    return {
      isOpenSection: false,
    };
  },
  computed: {
    droplistClass() {
      return this.isOpenSection ? 'droplist open' : 'droplist';
    },
    issetOptions() {
      return this.options.length > 1;
    },
    selectedName() {
      if (this.issetOptions) {
        const selected = this.options.filter((item) => item.id === this.selected);
        if (selected.length > 0) {
          return selected[0].name;
        }
        return this.defaultName;
      }
      return this.options[0]?.name || this.defaultName || '';
    },
  },
  methods: {
    selectUpdate(selected) {
      this.isOpenSection = false;
      this.$emit('update', selected);
    },
  },
};
</script>
<style lang="scss" scoped>
.droplist {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid #F2F6FD;
  border-radius: 16px;
  background: #FEFEFE;

  &.open {
    position: relative;
    z-index: 200;
    height: auto;

    .droplist--status-line {
      border-bottom: 1px solid #F2F6FD;
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 760px;
    height: 64px;
    padding: 6px 12px;
    margin: 0 auto;
  }
}
.droplist--status-line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 52px;
  padding: 0 12px;
}
.droplist--selected {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.droplist--controller {
  flex: 0 1 auto;
}
.droplist--selection {
  ul {
    margin: 0 1px;
    padding: 6px 0;
    list-style: none;
    max-height: calc(var(--vh, 1vh)* 50);
    overflow-y: scroll;

    li {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      padding: 8px 12px;
      .sub-text {
        color: #95969d;
      }
      &.selected {
        background: #F2F6FD;
        color: #356899;
      }
    }
  }
}
.droplist-button {
  height: 52px;
  width: 52px;
  background: transparent;
  border: none;
}
</style>
