<template>
  <div class="filter-line">
    <up-input
      :propValue="value"
      @onInput="(e) => value = e"
    />
    <button
      type="button"
      @click="clickButton"
      class="search-button"
    >
      <i class="exu-search-1" />
    </button>
  </div>
</template>
<script>
export default {
  name: 'upInputFilter',
  data() {
    return {
      localValue: null,
    };
  },
  computed: {
    value: {
      get() {
        return this.localValue;
      },
      set(data) {
        this.localValue = data;
        // this.$emit('onInput', data);
      },
    },
  },
  methods: {
    clickButton() {
      this.$emit('onSearch', this.localValue);
    },
  },
};
</script>
<style lang="scss">
.filter-line {
  display: flex;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 10px 20px 5px;
  background: #F6F6FA;

  input.up-form-control {
    flex: 1 1 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .search-button {
    flex: 0 0 50px;
    border: 1px solid #e0e0e0;
    border-left: none;
    border-radius: 0 12px 12px 0;

    &:active {
      background-color: #D76535;
      color: #F6F6FA;
    }
  }
}
</style>
