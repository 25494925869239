export default {
  methods: {
    isIncludesExhIDinCache(exhID) {
      const jsonListID = localStorage.getItem('listID');
      if (!jsonListID) {
        localStorage.setItem('listID', JSON.stringify([]));
        return false;
      }
      const listID = JSON.parse(jsonListID);
      return listID.includes(parseInt(exhID, 10));
    },
    addExhIDtoCache(exhID) {
      if (!this.isIncludesExhIDinCache(exhID)) {
        const listID = JSON.parse(localStorage.getItem('listID'));
        listID.push(exhID);
        localStorage.setItem('listID', JSON.stringify(listID));
        return true;
      }
      console.error('the exhID is already cached');
      return false;
    },
    removeExhIDfromCache(exhID) {
      const jsonDayList = localStorage.getItem(`exh-${exhID}-day-list`);
      const dayList = jsonDayList && JSON.parse(jsonDayList);

      if (Array.isArray(dayList)) {
        dayList.forEach((day) => {
          localStorage.removeItem(`exh-${exhID}-bp-${day}`);
        });
      }

      localStorage.removeItem(`exhID-${exhID}-config`);
      localStorage.removeItem(`exh-${exhID}-day-list`);
      localStorage.removeItem(`exh-${exhID}-hall-list`);
      // localStorage.removeItem(`exh-${exhID}-calendar`);
      localStorage.removeItem(`exh-${exhID}-faq`);
      localStorage.removeItem(`exh-${exhID}-map`);

      const listID = JSON.parse(localStorage.getItem('listID'));
      if (Array.isArray(listID) && listID.includes(parseInt(exhID, 10))) {
        const index = listID.indexOf(exhID);
        if (index > -1) {
          listID.splice(index, 1);
          localStorage.setItem('listID', JSON.stringify(listID));
        }
      }
    },
    // @curListID {Array} - список ID актуальных мероприятий полученный от API сервера
    clearJunkCache(curListID) {
      const jsonListID = localStorage.getItem('listID');
      const listID = jsonListID ? JSON.parse(jsonListID) : [];
      new Set([...curListID, ...listID]).forEach((exhID) => {
        if (!listID.includes(parseInt(exhID, 10))) this.addExhIDtoCache(exhID);
        if (!curListID.includes(parseInt(exhID, 10))) this.removeExhIDfromCache(exhID);
      });
    },
  },
};
