<template>
  <div class="profile__wrapper">
    <profile-top
      :photo="photo"
      :name="name"
      :position="position"
      :company="company"
    />
    <div class="profile-content full-size">
      <div
        v-if="isBarcode"
        class="hot-link-panel"
      >
        <div class="row">
          <div class="col-12">
            <up-card>
              <div>
                <h4 class="m-0 text-center">{{ $t('userProfile.barcode_title') }}</h4>
                <svg
                  id="barcode"
                  class="profile-barcode"
                ></svg>
              </div>
            </up-card>
          </div>
        </div>
      </div>
      <div
        v-if="isHotPanel"
        class="hot-link-panel"
      >
          <div class="row g-2 justify-content-evenly">
              <div class="col-6">
                <up-card>
                  <div class="hot-link">
                    <i class="exu-mail-2" />
                    <span class="hot-link-text">{{ $t('userProfile.messages') }}</span>
                  </div>
                  <template v-slot:stretch-link>
                    <router-link
                      :to="{ path: '/chat' }"
                      class="stretch-link"
                    />
                  </template>
                </up-card>
              </div>
              <div class="col-6">
                <up-card />
              </div>
          </div>
      </div>
    </div>
    <div class="profile-content__wrapper">
      <div class="profile-content">
      </div>
    </div>
  </div>
</template>

<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import userMethods from '@/components/mixins/methodsUser';
import ExuBarcode from '@//components/mixins/barcodeMethods';

export default {
  name: 'UserProfile',
  props: {
    id: {
      requared: true,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      userData: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  mixins: [appBaseNav, userMethods, ExuBarcode],
  computed: {
    name() {
      return this.userData.name || '';
    },
    position() {
      return this.userData.position || '';
    },
    company() {
      return this.userData.company || '';
    },
    photo() {
      return this.userData.photo || '';
    },
    isBarcode() {
      return !!this.userData.user_code;
    },
    barcode() {
      return this.userData.user_code;
    },
    isHotPanel() {
      return false;
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('userProfile.header_title'));
    },
  },
  beforeCreate() {
    try {
      if (!this.$store.getters.isAuth) {
        this.$router.push({ name: 'loginView' });
      }
    } catch (e) {
      console.error(e);
    }
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('userProfile.header_title'));
    if (this.$store.getters.isAuth) {
      this.$emit('loading', true);
      this.loadCached();
      this.getUserProfile()
        .then((result) => {
          this.$emit('loading', false);
          this.userData = { ...result };
          return this.barcode;
        // })
        // .then((barcode) => {
        //   if (barcode) {
        //     this.createBarcode('barcode', barcode);
        //   }
        });
    }
  },
  updated() {
    if (this.isBarcode) {
      this.createBarcode('barcode', this.barcode);
    }
  },
  methods: {
    loadCached() {
      const jsonCachingProfile = localStorage.getItem('profile');
      if (jsonCachingProfile) {
        this.userData = JSON.parse(jsonCachingProfile);
        this.$emit('loading', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  &__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-height: 100%;
  padding-bottom: 24px;
}
  &-content {
    &__wrapper {
      display: flex;
      align-items: center;
      flex: 3 1 100%;
      align-self: stretch;
    }
  }

  &-barcode {
    width: 100%;
  }
}
.profile-content {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &.full-size {
    width: 100%;
  }

  .hot-link-panel {
    width: 100%;
    color: #95969D;

    i {
      font-size: 24px;
    }
  }
  .hot-link {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    padding: 12px 10px;

    @media(max-width: 450px) {
      & {
        padding: 24px 10px;
      }
    }

    .hot-link-text {

      display: block;
      font-size: 14px;
      line-height: 1.15em;
      color: #95969D;
      text-decoration: none;
      text-align: left;
    }
  }

  .description-paragraph {
    color: #95969D;
    // font-family: Roboto;
    // font-size: 14px;
    text-align: justify;
    margin: 0;
  }
}
</style>
