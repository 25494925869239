import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    sendLogin(data) {
      return this.baseAxios({
        method: 'post',
        url: `${this.$store.getters.apiBasePath}/login`,
        data,
      })
        .then((_data) => {
          console.log(_data);
          return _data?.results ? _data.results : null;
        })
        .catch((error) => {
          Promise.reject(error);
        });
    },
  },
};
