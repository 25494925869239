<template>
<div class="navtab-line__wrapper">
  <div class="navtab-line">
    <div class="navtab-line__inner">
      <router-link
        v-for="(tab, index) in tabs"
        :key="'tab-'+index"
        :to="{ path: `/program/${tab.url}` }"
        :class="{ 'active': curUrl === tab.url }"
        class="tab-item"
        @click="handleClick(tab.url)"
      >
        {{ tab.name }}
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'NavTab',
  props: {
    tabs: {
      type: Array,
      default: () => ([]),
    },
    curUrl: null,
  },
  mounted() {
  },
  methods: {
    handleClick(date) {
      this.$emit('change-day', date);
    },
  },
};
</script>

<style lang="scss">
.navtab-line {
  width: 100%;
  height: 100%;
  padding: 0 4px;
  background: #F2F6FD;
  border-radius: 16px;
  overflow: auto hidden;
  color: #0D0D26;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;               /* width of the entire scrollbar */
  }
  &:focus::-webkit-scrollbar {
    width: 4px;
    height: 4px;               /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: rgba(53,104,153,0);        /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(26,51,76,0);    /* color of the scroll thumb */
    border-radius: 2px;       /* roundness of the scroll thumb */
    border: none;  /* creates padding around scroll thumb */
  }
  &:focus::-webkit-scrollbar-track {
    background: rgba(53,104,153,.25);        /* color of the tracking area */
  }

  &:focus::-webkit-scrollbar-thumb {
    background-color: rgba(26,51,76,.25);    /* color of the scroll thumb */
    border-radius: 2px;       /* roundness of the scroll thumb */
    border: none;  /* creates padding around scroll thumb */
  }

  &__wrapper {
    width: 100%;
    max-width: 760px;
    height: 64px;
    margin: 0 auto;
    padding: 6px 12px;
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
    gap: 0;
  }

  .tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 160px;
    height: 40px;
    border-radius: 10px;
    color: #2c3e50;
    text-decoration: none;

    &.active {
      background: #fefefe;
    }
  }
}
</style>
