<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <ul class="events-list">
    <li
      v-for="itemEvent of eventsList"
      :key="`event-${itemEvent.id}`"
    >
    <up-card class="upCard-sm">
      <div
        :class="{'event-item-card__archived': !itemEvent.is_public}"
        class="event-item-card__wrapper"
      >
        <div
          :class="{'selected': itemEvent.id == exhID && itemEvent.is_public}"
          class="event-item-card"
        >
          <strong>{{ itemEvent.name }}</strong>
          <small>{{ dateInterval(itemEvent.date_start_ts, itemEvent.date_end_ts) }}</small>
        </div>
        <div
          v-if="itemEvent.is_public"
          class="event-login"
        >
          <i class="exu-chevron-right"/>
        </div>
      </div>
      <template
        v-if="itemEvent.is_public"
        v-slot:stretch-link
      >
        <a
          class="stretch-link"
          @click.prevent="selectEventId(itemEvent.id)"
        />
      </template>
    </up-card>
    </li>
  </ul>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import methodsEventsList from '@/components/mixins/methodsEventsList';
import methodsCaching from '@/components/mixins/methodsCaching';
import timeCombaine from '@/components/mixins/timeCombaine';

export default {
  name: 'EventsListView',
  props: ['launchDownload', 'locale'],
  data() {
    return {
      countPage: null,
      curPage: 1,
      isLoading: true,
      eventsList: [],
    };
  },
  mixins: [appBaseNav, methodsEventsList, methodsCaching, timeCombaine],
  watch: {
    // launchDownload() {
    //   if (!this.isLoading && this.launchDownload) {
    //     this.curPage += 1;
    //     this.getPage();
    //   }
    // },
    // locale() {
    //   this.$store.commit('setPageTitle', this.$t('speakerList.header_title'));
    // },
  },
  computed: {
    exhID: {
      set(val) {
        this.$store.commit('setExhId', val);
      },
      get() {
        return this.$store.getters.getExhId;
      },
    },
  },
  mounted() {
    this.$store.commit('setMainTitle', { lang: 'ru', text: '' });
    this.$store.commit('setMainTitle', { lang: 'en', text: '' });
    this.$store.commit('setPageTitle', '');

    const jsonCachedData = localStorage.getItem('event-list');
    if (jsonCachedData) {
      const cachedData = JSON.parse(jsonCachedData);
      this.$store.commit('setMainTitle', { lang: 'ru', text: cachedData.appname });
      this.eventsList = [...this.eventsList, ...cachedData.results];
      this.countPage = cachedData.num_pages;
      this.curPage = cachedData.page;
    }

    this.getPage(this.curPage);
  },
  methods: {
    getPage() {
      this.isLoading = true;

      // if (this.countPage === null || this.curPage <= this.countPage) {
      this.getEventsList(this.curPage)
        .then((data) => {
          this.$emit('turnOff', {
            stateDownload: false,
            stateCheckInbox: false,
          });
          if (data) {
            this.$store.commit('setMainTitle', { lang: 'ru', text: data.appname });
            this.eventsList = [...data.results];
            this.countPage = data.num_pages;
            this.curPage = data.page;
          }
        })
        .then(() => {
          this.clearJunkCache(this.eventsList.map((event) => event.id));
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
          return true;
        });
      // }
      // this.isLoading = false;
      // this.$emit('turnOff', {
      //   stateCheckInbox: true,
      // });
      // return true;
    },
    dateInterval(startTs, endTs) {
      return this.testDateSubtitle(startTs, endTs);
    },
    selectEventId(eventId) {
      this.exhID = eventId;
      this.$emit('updateConfig');
      this.$router.push({ name: 'home' });
    },
  },
};
</script>
<style lang="scss" scoped>
.events-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 12px;
  list-style: none;
}
.event-item-card {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
  gap: .25rem;

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 5.5rem;
    gap: .5rem;
  }
  &__archived {
    color: #95969D;

    & div strong {
      color: #95969D;
    }
  }

  & strong {
    color: #356899;
  }

  &.selected {
    strong {
      color: #D76535;
    }
  }
}
.event-login {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  font-size: 1.75rem;
}
</style>
