<template>
  <grid-menu
    :locale="locale"
    @switch-locale="changeLocale"
  />
</template>

<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import gridMenu from '@/components/shared/asideMenu/gridMenu.vue';

export default {
  name: 'DashboardView',
  components: { gridMenu },
  props: {
    locale: {
      type: String,
      default: 'ru',
    },
    updatePwaExist: {
      type: Boolean,
      default: false,
    },
  },
  // data() {
  //   return
  // },
  mixins: [appBaseNav],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$store.getters.getMainTitle);
    },
  },
  computed: {
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$store.getters.getMainTitle);
  },
  methods: {
    changeLocale() {
      this.$emit('switch-locale', this.locale === 'ru' ? 'en' : 'ru');
    },
    handleUpdatePwa() {
      this.$emit('update-pwa', !this.updatePWAExist);
    },
  },
};
</script>

<style lang="scss">
.menu__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
  height: 100%;
  // background: #FFF;
  // /* box-shadow: 0px -10px 10px 10px rgba(0, 0, 0, .25); */
  // transition: transform .5s;
  // border-left: 1px solid #e0e0e0;
}
</style>
