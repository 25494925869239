<template>
<div class="section__wrapper">
  <div class="title-line">
    <h5>{{ sectionTitle }}</h5>
  </div>
  <div
    v-if="!isShowForm"
    class="filter-line"
  >
    <drop-list
      :options="optionsLectures"
      :selected="selectedLectureId"
      :default-name="$t('questionList.choose_theme')"
      @update="handleUpdateSelected"
    />
  </div>
  <div
    v-if="!selectedLecture"
    class="help-card__wrapper"
  >
    <up-card
      class="help-card"
    >
      <div class="help-meesage">
          <div class="icon-block">
            <i class="exu-chevron-up"></i>
          </div>
          <div class="text-block" v-html="$t('questionList.help')" />
      </div>
    </up-card>
  </div>
  <div
    v-if="selectedLecture"
    class="title-line"
  >
    <p>{{ $t('questionList.title_pre_theme') }} "{{ fullTitleLecture }}"</p>
  </div>
  <questions-list
    v-if="selectedLecture && !isShowForm"
    :lecture="selectedLecture"
  />
  <question-form
    v-if="isShowForm"
    :lecture="selectedLecture"
    @update-show-form="(state) => isShowForm = state"
  />
  <div
    v-if="isAuth && selectedLecture && !isShowForm"
    class="line-sticky-bottom"
  >
    <button
      class="btn btn-ask"
      @click="isShowForm = true"
    >
      {{ $t('questionList.form.btn_create_question') }}
    </button>
  </div>
</div>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import sections from '@/components/mixins/methodsSections';
import QuestionsList from './questionsList.vue';
import questionForm from './questionForm.vue';

export default {
  name: 'SectionDetails',
  mixins: [appBaseNav, sections],
  components: { QuestionsList, questionForm },
  props: {
    section_id: {
      type: [String, Number],
      required: true,
    },
    lecture_id: {
      type: [String, Number],
      default: null,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      section: null,
      // selectedLectureId: null,
      // optionsLectures: [],
      showingForm: false,
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth;
    },
    isShowForm: {
      get() {
        return this.showingForm;
      },
      set(newState) {
        this.showingForm = newState;
      },
    },
    sectionTitle() {
      return this.section?.title ? `${this.section?.name} ${this.section?.title}`.trim() : '';
    },
    optionsLectures() {
      if (this.section?.lectures.length > 0) {
        return this.section.lectures
          .filter((lecture) => lecture.type === 'lecture')
          .map((lecture) => ({
            id: lecture.id,
            name: lecture.title.length <= 30 ? lecture.title : `${lecture.title.slice(0, 30).trim()}...`,
            subtext: `${lecture.start.match(/([1-9]?\d{1}:\d{2})/)[0]} ${lecture.speakers.map((speaker) => speaker.name).join(', ')}`,
          }));
      }
      return [];
    },
    selectedLectureId: {
      get() {
        return parseInt(this.lecture_id, 10) || null;
      },
      set(_id) {
        this.$router.push({ name: 'lectureQuestions', params: { section_id: this.section_id, lecture_id: _id } });
      },
    },
    selectedLecture() {
      if (this.section?.lectures.length > 0 && this.selectedLectureId) {
        return this.section.lectures
          .filter((lecture) => lecture.id === this.selectedLectureId)[0] || null;
      }
      return null;
    },
    fullTitleLecture() {
      return this.selectedLecture?.title || null;
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('questionList.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('questionList.header_title'));
    this.$emit('loading', true);
    Promise.all([
      this.getSection(this.section_id),
    ])
      .then(([
        section,
      ]) => {
        this.section = { ...section };
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.$emit('loading', false);
      });
  },
  methods: {
    handleUpdateSelected(value) {
      this.selectedLectureId = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    // gap: 12px;
  }

  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 12px 12px;
}
.help-card {
  padding: 10px;
  color: #AFB0B6;

  .help-meesage {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .icon-block {
    display: flex;
    align-items: baseline;
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
    animation: moving-chevron 10s infinite ease-in-out 2s none;
  }
  .text-block {
    font-size: 1rem;
  }

  &__wrapper {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
}
.questions-list {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__wrapper {
    flex: 1 1 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__item {
    padding: 0 12px;
    p {
      margin: 0;
      &.break-line {
        white-space: break-spaces;
      }
    }
  }
}

.title-line {
  padding: 8px 12px 4px;
  background: #FEFEFE;
  & > * {
    margin: 0;
  }
  p {
    color: #356899;
  }
}
.filter-line {
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 0;
  border-bottom: 2px solid #F6F6FA;
}
.line-sticky-bottom {
  position: sticky;
  bottom: 0;
  background: #FEFEFE;
  padding: 8px 24px;
  border-top: 2px solid #F6F6FA;;
}
.btn-ask {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  color: #D76535;
  border-radius: 20px;
  border: 2px solid #D76535;
  background: #FEFEFE;
  &:active {
    background: #D76535;
    color: #FEFEFE;
  }
}
@keyframes moving-chevron {
  0% {
    transform: translateY(0);
    scale: 1;
  }
  // 1
  1% {
    transform: translateY(25%);
    scale: 1;
  }
  // 3
  4% {
    transform: translateY(-50%);
    scale: 1;
  }
  // 4
  8% {
    transform: translateY(50%);
    scale: 1;
  }
  // 3
  11% {
    transform: translateY(-25%);
    scale: 1;
  }
  13% {
    transform: translateY(12.5%);
    scale: 1;
  }
  14% {
    transform: translateY(-6.75%);
    scale: 1;
  }
  15% {
    transform: translateY(0%);
    scale: 1;
  }
  100% {
    transform: translateY(0);
    scale: 1;
  }
}
</style>
