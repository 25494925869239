<template>
  <div class="speaker-list">
    <div class="row">
      <div
        v-for="speaker in speackerList"
        :key="speaker.id"
        class="col-6 col-md-4"
      >
        <speaker-card
          :id="speaker.id"
          :photo="speaker.photo"
          :name="speaker.name"
          :company="speaker.company"
          :position="speaker.position"
          :description="speaker.description"
        />
      </div>
      <pre-loader v-if="isLoading"/>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import appBaseNav from '@/components/mixins/appBaseNav';
import speackers from '@/components/mixins/methodsSpeackers';
import banners from '@/components/mixins/methodsBanners';

export default {
  name: 'ListSpeakers',
  // TODO обработать входящий параметр - page (указатель с страницы начинать)
  // TODO реализовать подгрузку страницы при скролинге вниз и вверх
  props: ['launchDownload', 'locale'],
  data() {
    return {
      speackerList: [],
      isLoading: true,
      curPage: 1,
      countPage: null,
    };
  },
  mixins: [appBaseNav, speackers, banners],
  watch: {
    launchDownload() {
      if (!this.isLoading && this.launchDownload) {
        this.curPage += 1;
        this.getPage();
      }
    },
    locale() {
      this.$store.commit('setPageTitle', this.$t('speakerList.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('speakerList.header_title'));
    Promise.all([
      this.getBanner('speaker', this.$store.getters.getBannerSpeakerTs),
    ])
      .then(([
        banner,
      ]) => {
        this.isLoading = false;
        if (banner && Object.keys(banner).length) {
          this.$emit('showBanner', banner);
          const nowMilis = DateTime.now().toMillis();
          localStorage.setItem('bannerSpeakerTs', nowMilis);
          this.$store.commit('setBannerSpeakerTs', nowMilis);
        }
        return this.getPage(this.curPage);
      })
      .finally(() => {
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    getPage() {
      this.isLoading = true;
      if (this.countPage === null || this.curPage <= this.countPage) {
        return this.getSpeackersList(this.curPage)
          .then((data) => {
            this.isLoading = false;
            this.$emit('turnOff', {
              stateDownload: false,
              stateCheckInbox: false,
            });
            if (data.results && Array.isArray(data.results)) {
              this.speackerList = [...this.speackerList, ...data.results];
              this.countPage = data.num_pages;
              this.curPage = data.page;
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => true);
      }
      this.isLoading = false;
      this.$emit('turnOff', {
        stateCheckInbox: true,
      });
      return true;
    },

  },
};
</script>

<style lang="scss">
.speaker-list {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 20px;

  & > .row {
    margin: 0 -10px;
    row-gap: 20px;
    & > .col {
      padding: 0 10px;
    }
  }
}
</style>
