/* eslint-disable max-len */
import timeCombaine from '@/components/mixins/timeCombaine';
// import global from '@/components/mixins/methodsGlobal';

export default {
  data() {
    return {
      userCalendar: [],
    };
  },
  mixins: [timeCombaine],
  computed: {
    exhID() {
      return this.$store.getters.getExhId;
    },
    listMarkedID() {
      return this.userCalendar.map((item) => item.id);
    },
  },
  methods: {
    sortCalendar(calendar) {
      const sortCalendar = calendar.sort((itemA, itemB) => (itemA.start_ts !== itemB.start_ts
        ? itemA.start_ts - itemB.start_ts
        : itemA.duration_ts - itemB.duration_ts));
      const dateList = [];
      const resultCalendar = [];
      sortCalendar
        .filter((item) => item.type !== 'date')
        .forEach((item) => {
          const date = this.dateEventWeekday(this.timeStart(item.start_ts));
          if (!dateList.includes(date)) {
            dateList.push(date);
            resultCalendar.push({
              id: this.startDayTs(item.start_ts),
              start_ts: this.startDayTs(item.start_ts),
              type: 'date',
              text: date,
            });
          }
          resultCalendar.push(item);
        });
      return resultCalendar;
    },
    loadUserCalendar() {
      const jsonCalendar = localStorage.getItem(`exh-${this.exhID}-calendar`);
      const calendar = jsonCalendar ? JSON.parse(jsonCalendar) : [];
      if (calendar) this.userCalendar = this.sortCalendar(calendar);
    },
    saveUserCalendar() {
      // const calendar = this.loadUserCalendar();
      this.userCalendar = this.sortCalendar(this.userCalendar);
      localStorage.setItem(`exh-${this.exhID}-calendar`, JSON.stringify(this.userCalendar));
    },
    addToUserCalendar(item) {
      this.userCalendar = [...this.userCalendar, item];

      this.saveUserCalendar();
    },
    removeFromUserCalendar(id) {
      this.userCalendar = this.userCalendar.filter((item) => item.id !== id);

      this.saveUserCalendar();
    },
  },
};
