<!-- eslint-disable no-restricted-syntax -->
<!-- eslint-disable max-len -->
<template>
  <div class="calendar__wrapper">
    <div
      v-if="isSection"
      class="calendar"
    >
      <user-calendar-item
        v-for="lecture in lectures"
        :key="`calendar-item-${lecture.id}`"
        :item-data="lecture"
        @mark-favorite="onMarkFavorite"
      />
    </div>
    <div v-else>
      <up-card class="card-help">
        <h3>{{ $t('userCalendar.help_title') }}</h3>
        <p>
          {{ $t('userCalendar.help_string_1', { nbsp: '&nbsp;'}) }} <i class="exu-bookmark"/>.
        </p>
        <p>
          {{ $t('userCalendar.help_string_2') }}
        </p>
      </up-card>
    </div>
  </div>
</template>
<script>
import global from '@/components/mixins/methodsGlobal';
import appBaseNav from '@/components/mixins/appBaseNav';
import calendar from '@/components/mixins/methodsCalendar';
import userCalendarItem from './UserCalendarItem.vue';

export default {
  name: 'userCalendar',
  props: {
    locale: {
      type: String,
    },
  },
  data() {
    return {
      speaker: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  components: { userCalendarItem },
  mixins: [global, appBaseNav, calendar],
  computed: {
    isSection() {
      return this.userCalendar && this.userCalendar.length > 0;
    },
    lectures() {
      return this.userCalendar;
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('userCalendar.header_title'));
    },
  },
  created() {
    this.$store.commit('setPageTitle', this.$t('userCalendar.header_title'));
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('userCalendar.header_title'));
    this.loadUserCalendar();
    this.saveUserCalendar();
    this.sendLogMetrika(this.logMetrika({
      url: this.$route.fullPath,
      action: 'open calendar',
      props: JSON.stringify(this.userCalendar),
    }));
  },
  methods: {
    setComponent(type) {
      if (type === 'date') {
        return 'user-calendar-date-item';
      }
      return 'user-calendar-item';
    },
    onMarkFavorite(data) {
      if (this.listMarkedID.includes(data.id)) {
        this.removeFromUserCalendar(data.id);
        this.sendLogMetrika(this.logMetrika({
          url: this.$route.fullPath,
          action: 'remove from calendar',
          props: JSON.stringify(this.userCalendar),
        }));
      } else {
        this.addToUserCalendar(data);
        this.sendLogMetrika(this.logMetrika({
          url: this.$route.fullPath,
          action: 'add to calendar',
          props: JSON.stringify(this.userCalendar),
        }));
      }
    },
  },
};
</script>
<style lang="scss">
.calendar {
  &__wrapper {
    max-width: 760px;
    padding: 24px 12px;
    margin-left: auto;
    margin-right: auto;
  }

  display: flex;
  flex-direction: column;

  &-day {
    &__wrapper {
      display: flex;
    }
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    background: #356899;
    border-radius: 16px;
    color: #FFFFFF;
    text-transform: capitalize;
  }
  &-item {
    &__wrapper {
      &:last-child {
        &::before {
          height: 50%;
        }
      }
      &::before {
        content: '';
        display: block;
        grid-area: solidline;
        background: #356899;
      }
      & .timemark {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-area: timemarker;
        border: 2px solid #356899;
        border-radius: 50%;
        background: #FFFFFF;
        font-size: 1rem;

        &.bookmark {
          color: #356899;
          // color: #D76535;
        }
      }

      display: grid;
      grid-template-columns:
        [timemarker-start] 14px [solidline-start] 2px [solidline-end] 14px
        [timemarker-end] 10px [item-start] 1fr [item-end];
      grid-template-rows: [solidline-start item-start] 1fr [timemarker-start] 30px
        [timemarker-end] 1fr [solidline-end item-end];

      position: relative;
      padding-left: 2px;
      gap: 0px;
    }
    grid-area: item;
    padding: 8px 0;

    &__inner {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 14px;
      line-height: 130%;
      // letter-spacing: ;

      & p {
        margin: 0;
      }

      & .time-place {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;
        gap: 0 .5em;
        color: #2c3e50;
        font-style: normal;
        font-weight: 500;
        white-space: nowrap;

        & .calendar-item-time {
          font-weight: 500;
        }
      }
      & .title-block {
        display: flex;
        flex-direction: column;
        gap: .2rem;

        & .main-title {
          color: #356899;
          font-style: normal;
          font-weight: 500;
        }
        & .sub-title {
          color: #356899;
          font-style: normal;
        }
      }
      & .system-comment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #95969D;
      }
      & .up-btn.bookmark, & .btn.bookmark {
        width: 36px;
        height: 36px;
        padding: 0;
        border-width: 1px;
        border-radius: 5px;
        font-size: 1.2rem;
        line-height: 1rem;
        text-align: center;
        border: 2px solid #D76535;
        background: #D76535;
        color: #FFFFFF;
      }
      & .upCard-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: .5rem;
      }
    }
  }
}
.card-help {
  padding: 16px;
  color: #AFB0B6;
  & i {
    color: #D76535;
  }
  & h3 {
    text-align: center;
  }
  & p {
    max-width: 280px;
    text-align: justify;
    margin: 0 auto;
  }
  & p+p {
    margin-top: .5rem;
  }
}
</style>
