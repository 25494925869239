import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getSponsorList(page = 1) {
      this.$store.commit('setProgressVal', 1);
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/sponsor/page/${page ? `${page}/` : '1/'}`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => data || {})
        .catch(() => {
          this.$store.commit('setProgressVal', 100);
          return false;
        });
    },
    getSponsorPersone(id) {
      this.$store.commit('setProgressVal', 1);
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/sponsor/${id}`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => data.results || {})
        .catch(() => {
          this.$store.commit('setProgressVal', 100);
          return false;
        });
    },
    getSponsorCategory() {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/sponsor/category`,
      })
        .then((data) => data.results || {})
        .catch(() => {
          this.$store.commit('setProgressVal', 100);
          return false;
        });
    },

    // По идее это про участников
    getSponsorByStand(_standID) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/sponsor/stand/${_standID}`,
      })
        .then((data) => data.results || null)
        .catch((error) => {
          console.error(error);
          return null;
        });
    },
  },
};
