<template>
  <div class="lecture-item">
    <div class="lecture-time">
      <p class="time-start">
        {{ $t('shared.lecture.start_at_label') }} {{ timeStart }}
      </p>
      <p class="time-start">
        {{ lecture.duration }} {{ $t('shared.lecture.minuts') }}
      </p>
    </div>
    <div class="lecture-title">
      <p>
        {{ lecture.title }}
      </p>
    </div>
    <div class="lecture-mark">
      <button
        @click="lectureMark()"
        class="btn btn-sm btn-accent"
      >
        <i :class="{
          'exu-bookmark': !markedLecture,
          'exu-bookmark-check-fill': markedLecture
        }" />
      </button>
    </div>
    <div
      v-if="isModerator && isSpeakers"
      class="lecture-moderate">
      <span class="moderate-item">{{ $t('shared.lecture.moderator') }}:</span>
        <span
          v-for="moderator in ModeratorList"
          :key="moderator.id"
          class="moderate-item"
        >
          {{ moderator.name}}
        </span>
    </div>
    <div
      v-if="isSpeakers"
      class="lecture-speaker">
      <i class="exu-mic"/>
      <router-link
        v-for="speaker in lecture.speakers"
        :key="speaker.id"
        :to="{ path: speakerLink(speaker.id) }"
        class="speaker-item"
      >
        {{ speaker. name}}
      </router-link>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';

export default {
  name: 'LectureItem',
  props: {
    lecture: {
      type: Object,
      default: () => ({}),
    },
    moderators: {
      type: Array,
      default: () => ([]),
    },
    markedLecture: {
      type: Boolean,
      default: false,
    },
    doScrollLecture: {
      default: null,
    },
  },
  computed: {
    timeStart() {
      // TODO fix global UTC
      return DateTime.fromMillis(this.lecture.start_ts).setZone('UTC+0').toFormat('H:mm');
    },
    isModerator() {
      return this.lecture.moderators.length > 0 || null;
    },
    ModeratorList() {
      if (this.lecture.moderators.length > 0) {
        return this.lecture.moderators;
      }
      return [];
    },
    isSpeakers() {
      return this.lecture.speakers.length > 0;
    },
    dataForMarkFavorit() {
      return {
        id: this.lecture.id,
        start_ts: this.lecture.start_ts,
        duration_ts: this.lecture.duration_ts,
        place: null,
        main_title: null,
        sub_title: this.lecture.title,
        moderators: [],
        speakers: this.isSpeakers ? this.lecture.speakers : [],
        type: 'lecture',
      };
    },
  },
  mounted() {
    if (this.doScrollLecture) {
      document.querySelector(`#${this.doScrollLecture}`)
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  },
  methods: {
    // moderatorLink(id) {
    //   return `/speaker/profile/${id}`;
    // },
    speakerLink(id) {
      return `/speaker/profile/${id}`;
    },
    lectureMark() {
      this.$emit('mark-lecture', this.dataForMarkFavorit);
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-item {
  display: grid;
  grid-template-columns: [left-line] 1fr [middle-line] auto [right-line];
  grid-template-rows: [top-line] auto [time-line] auto
    [title-line] auto [bottom-line];
  gap: 3px 5px;

  scroll-margin-top: 135px;

  font-size: 14px;
  .lecture-time {
    grid-column: left-line / right-line;
    grid-row: top-line / time-line;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #356899;

    &>p {
      margin: 0;
    }
  }

  .lecture-mark {
    grid-column: middle-line / right-line;
    grid-row: time-line / bottom-line;
  }

  .lecture-title {
    grid-column: left-line / middle-line;
    grid-row: time-line / title-line;
    color: #356899;
    font-style: normal;
    line-height: 1.2rem;
    &>p {
      margin: 0;
    }
  }

  .lecture-speaker {
    grid-column: left-line / middle-line;
    grid-row: title-line / bottom-line;
  }

  .lecture-speaker, .lecture-moderate {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 15px;
    align-items: center;
    color: #95969D;

    .speaker-item, .moderate-item {
      display: block;
      color: #2c3e50;
      font-size: 14px;
      text-decoration: none;
      outline: none;
      white-space: nowrap;

      &.current {
        color: #356899;
      }
    }
  }
}
.btn-accent {
  color: #D76535;
  font-size: 1.5rem;
  padding: 0;
  border: none;
  // border: 2px solid #D76535;

  &:active {
    color: #D76535;
    // color: #FFFFFF;
    // background: #D76535;
  }
}
</style>
