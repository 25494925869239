import JsBarcode from 'jsbarcode';

export default {
  name: 'ExuBarcode',
  methods: {
    // @elementID {string} - ID элемента в который будет выведен штрихкод, svg, canvas, img.
    // @barcode {string} - Значение закодированное в штрихкод.
    createBarcode(elmentID, barcode) {
      JsBarcode(`#${elmentID}`, barcode, {
        format: 'ean13',
        width: 3,
        height: 80,
        lineColor: '#2c3e50',
        fontSize: 10,
        textMargin: -5,
        margin: 5,
        displayValue: false,
      });
      document.getElementById(elmentID).removeAttribute('width');
      document.getElementById(elmentID).removeAttribute('height');
    },
  },
};
