<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
<div class="login__wrapper">
  <div class="login">
    <div
      v-if="infoMessage"
      class="row"
    >
      <div class="col-12">
        <div class="alert alert-info">
          {{ infoMessage }}
        </div>
      </div>
    </div>
    <div class="row gy-3">
      <div class="col-12">
        <input
          v-model="userAppId"
          placeholder="App ID"
          type="text"
          class="form-input"
        >
      </div>
      <div class="d-grid">
        <button
          :disabled="isClickedbtnLogin"
          class="btn btn-outline-primary btn-block"
          @click="handleSendLogin"
        >
          <span v-if="!isClickedbtnLogin">{{ $t('login.btn_enter') }}</span>
          <pre-loader v-else/>
        </button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import Login from '@/components/mixins/methodsLogin';
import userMethods from '@/components/mixins/methodsUser';

export default {
  name: 'LoginView',
  props: ['locale'],
  mixins: [appBaseNav, Login, userMethods],
  data() {
    return {
      exId: 1,
      appId: null,

      infoMessage: null,
      isClickedbtnLogin: false,
    };
  },
  computed: {
    userAppId: {
      get() {
        return this.appId;
      },
      set(value) {
        this.appId = value;
      },
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('login.header_title'));
    },
  },
  created() {
    try {
      if (this.$store.getters.isAuth) {
        this.pushToLastUrl();
      }
    } catch (e) {
      console.error(e);
    }
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('login.header_title'));
    // TODO
    this.$store.commit('setProgressVal', 0);
  },
  methods: {
    handleSendLogin() {
      this.isClickedbtnLogin = true;
      this.sendLogin({
        app_id: this.appId,
      })
        // eslint-disable-next-line consistent-return
        .then((data) => {
          if (data?.token) {
            this.$store.commit('setToken', data.token);
            localStorage.setItem('token', data.token);

            return this.getUserProfile();
          }
          if (data?.message) {
            return { message: data.message };
          }
          return { message: this.$t('system.net_unavailable') };
        })
        .then((data) => {
          if (data.message) {
            this.infoMessage = data.message;
          }
          if (data.id) {
            this.$store.commit('setUserID', data.id || null);
            this.pushToLastUrl();
          }
        })
        .finally(() => {
          this.isClickedbtnLogin = false;
        });
    },
    pushToLastUrl() {
      const { name, params } = this.$store.getters.getLastUrl || {};
      if (name) {
        this.$router.push({ name, params });
      } else {
        this.$router.push({ name: 'DashboardView' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  max-width: 450px;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 16px;
  }
  .form-input {
    width: 100%;
    height: 52px;
    padding: 0 12px;
    border: 1px solid #356899;
    border-radius: 16px;
    background: #FEFEFE;
  }
  .btn {
    height: 52px;
    border-radius: 16px;

    & .preloader__wrapper {
      transform: scale(.5);
    }
  }
  .btn-outline-primary {
    border-color: #356899;
    color: #356899;
    // &:focus {
    //   background: #FEFEFE;
    //   color: #356899;
    // }
    &:hover, &:focus {
      // background: #356899;
      // color: #FEFEFE;
      background: #FEFEFE;
      color: #356899;
    }
  }
}
</style>
