<template>
  <div class="chatView">
    <chat-room
      v-if="uuid"
      :uuid="uuid"
    />
    <chat-list v-else />
  </div>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import methodsChat from '@/components/mixins/methodsChat';
import participants from '@/components/mixins/methodsParticipants';
import chatList from './chatList.vue';
import chatRoom from './chatRoom.vue';

export default {
  name: 'chatView',
  props: {
    uuid: null,
    locale: {
      type: String,
    },
  },
  data() {
    return {
      messages: {
        type: Array,
        default: [],
      },
    };
  },
  components: { chatList, chatRoom },
  mixins: [appBaseNav, methodsChat, participants],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('messenger.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('messenger.header_title'));
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
  .chatView {
    display: grid;
    grid-template-columns:
      [chat-start inputField-start] 1fr [inputField-end chat-end];
    grid-template-rows:
      [chat-start] 1fr [chat-end inputField-start] 40px [inputField-end];
    gap: 10px;
    width: 100%;
    max-width: 760px;
    height: 100%;
    margin: 0 auto;
    padding: 20px 20px 10px;
  }
  .chat {
    &__wrapper {
      grid-area: chat;
      overflow-x: hidden;
      overflow-y: auto;
    }
    &__list {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .message {
        &__wrapper {
          display: flex;
          width: 100%;
          justify-content: center;

          &.invided-message {
            justify-content: start;
            & > div {
              background-color: #D0E8F8;
              border: 1px solid #a5bdb2;
              border-bottom-left-radius: 0;
            }
          }
          &.main-message {
            justify-content: end;
            // & > div { background-color: #CFE0CF; }
            & > div {
              background-color: #CCFFCC;
              border: 1px solid #a5bdb2;
              border-bottom-right-radius: 0;
            }
          }
          &.tech-message {
            & > div {
              background: rgba(254,254,254,.75);
              border: 1px solid #CCCCCC;
              color: #A0A0A0;
              padding: 3px 12px;
              font-size: .8rem;
            }
          }
        }
        &-item {
          max-width: 85%;
          padding: 10px;
          border-radius: 10px;

          .status-message {
            font-size: .8rem;
          }
        }
      }
    }
    &__input-row {
      grid-area: inputField;

      input {
        height: 36px;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 12px;
        padding: 5px 15px;
      }
    }
  }
</style>
