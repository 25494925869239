export default {
  mounted() {
    switch (this.$route.name) {
      case 'EventsListView':
        this.$store.commit('visibleBtnMenu', false);
        this.$store.commit('visibleBtnToEventList', false);
        this.$store.commit('visiblBottomNav', false);
        break;
      case 'DashboardView':
        this.$store.commit('visibleBtnMenu', false);
        this.$store.commit('visibleBtnToEventList', true);
        this.$store.commit('visiblBottomNav', false);
        break;
      default:
        this.$store.commit('visibleBtnMenu', true);
        this.$store.commit('visibleBtnToEventList', false);
        this.$store.commit('visiblBottomNav', true);
        break;
    }
  },
};
