<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <up-card class="section-card overflow-unset">
    <div :class="{ 'section__wrapper': true, 'isOpen': isOpenSection }">
      <div
        class="section-header"
        @click="isOpenSection = !isOpenSection"
      >
        <div class="section-line">
          <p class="section-time">
            {{ timeStartEnd }}
          </p>
          <p class="section-place">
            {{ section.place }}
          </p>
        </div>
        <div
          class="section-line"
          @click="isOpenSection = !isOpenSection"
        >
          <p class="section-title">
            {{ sectionTitle }}
          </p>
        </div>
      </div>
      <div class="section-body">
        <div
          v-if="isModerators"
          class="section-moderate">
          <span class="moderate-item">{{ $t('shared.lecture.moderator') }}:</span>
          <span
            v-for="moderator in section.moderators"
            :key="moderator.id"
            class="moderate-item"
          >
            {{ moderator.name}}
          </span>
        </div>
        <div
          v-if="isSpeakers"
          class="section-speaker">
          <i class="exu-mic"/>
          <router-link
            v-for="speaker in section.speakers"
            :key="speaker.id"
            :to="{ path: speakerLink(speaker.id) }"
            class="speaker-item"
          >
            {{ speaker. name}}
          </router-link>
        </div>
        <div
          v-if="isOpenSection"
          class="section-lecture"
        >
          <lecture-item
            v-for="lecture in section.lectures"
            :id="`s_${section.id}_l_${lecture.id}`"
            :key="`s_${section.id}_l_${lecture.id}`"
            :lecture="lecture"
            :moderators="section.moderators"
            :marked-lecture="isMarkedLecture(lecture.id)"
            :doScrollLecture = "doScrollItem.lecture === `${lecture.id}` ? hashToScroll : null"
            @mark-lecture="markLectureFavorit"
          />
        </div>
        <div
          v-if="isNoBreake"
          class="section-line button-line"
        >
          <div class="col-3">
            <button
              @click="markFavorit"
              class="btn btn-sm btn-accent"
              :class="{ active: isMarkedSection }"
            >
            <i :class="{
              'exu-bookmark': !isMarkedSection,
              'exu-bookmark-check-fill': isMarkedSection
            }" />
            </button>
          </div>
          <div
            v-if="isBtnMore"
            class="col"
          >
            <button
              class="btn btn-sm btn-more"
              @click="isOpenSection = !isOpenSection"
            >
              <i
                v-if="!isOpenSection"
                class="exu-chevron-down"
              />
              <i
                v-else
                class="exu-chevron-up"
              />
            </button>
          </div>
          <div
            v-if="isBtnMore"
            class="col-3"
          >
            <router-link
              :to="{ name: 'questions', params: { section_id: this.section.id } }"
              class="btn btn-sm btn-accent"
            >
              <i class="exu-question" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </up-card>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';

export default {
  name: 'SectionCard',
  // mixins: [calendar],
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
    markedId: {
      type: Array,
      default: () => ([]),
    },
    hashToScroll: null,
    doScrollItem: null,
  },
  data() {
    return {
      isOpenSection: false,
    };
  },
  computed: {
    checkLecture() {
      return !!this.lecture;
    },
    isNoBreake() {
      // TODO уточнить про тип section
      return this.section.type === 'lecture' || this.section.type === 'section';
    },
    timeStart() {
      // TODO fix global UTC
      return DateTime.fromMillis(this.section.start_ts).setZone('UTC+0');
    },
    timeEnd() {
      return this.timeStart.plus(this.section.duration_ts);
    },
    timeStartEnd() {
      return this.timeStart ? `${this.timeStart.toFormat('H:mm')} - ${this.timeEnd.toFormat('H:mm')}` : '';
    },
    dateEvent() {
      return this.timeStart ? this.timeStart.setLocale('ru').toFormat('L MMMM') : '';
    },
    // sectionName() {
    //   return this.section.name
    // eslint-disable-next-line max-len
    //     ? `${this.section.name}${this.section.name[this.section.name.length - 1] !== '.' ? '.' : ''}`.trim()
    //     : null;
    // },
    sectionTitle() {
      const sectionName = this.section.name ? this.section.name : '';
      const sectionTitle = this.section.title ? this.section.title : '';
      const dotName = sectionName && sectionName[sectionName.length - 1] !== '.' ? '.' : '';
      const dotTitle = sectionTitle && sectionTitle[sectionTitle.length - 1] !== '.' ? '.' : '';
      const title = `${sectionName}${dotName} ${sectionTitle}${dotTitle}`;

      return title;
    },
    isBtnMore() {
      return this.section.lectures.length > 0;
    },
    isModerators() {
      return this.section.moderators.length > 0;
    },
    isSpeakers() {
      return this.section.speakers.length > 0 && !this.isOpenSection;
    },
    isMarkedSection() {
      return this.markedId.includes(this.section.id);
    },
    dataForMarkFavorit() {
      return {
        id: this.section.id,
        hashLink: `s_${this.section.id}`,
        start_ts: this.section.start_ts,
        duration_ts: this.section.duration_ts,
        place: this.section.place,
        main_title: this.sectionTitle,
        sub_title: null,
        moderators: this.isModerators ? this.section.moderators : [],
        speakers: this.isSpeakers ? this.section.speakers : [],
        type: 'section',
      };
    },
  },
  mounted() {
    if (this.doScrollItem) {
      if (!this.doScrollItem.lecture) {
        document.querySelector(`#s_${this.doScrollItem.section}`)
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        this.isOpenSection = true;
      }
    }
  },
  methods: {
    // moderatorLink(id) {
    //   return `/speaker/profile/${id}`;
    // },
    speakerLink(id) {
      return `/speaker/profile/${id}`;
    },
    markFavorit() {
      this.$emit('mark-favorite', this.dataForMarkFavorit);
    },
    markLectureFavorit(data) {
      console.log(data);
      const lectureData = data;
      lectureData.hashLink = `s_${this.section.id}_l_${data.id}`;
      lectureData.main_title = this.sectionTitle;
      lectureData.place = this.section.place;
      lectureData.moderators = this.isModerators ? this.section.moderators : [];

      this.$emit('mark-favorite', lectureData);
    },
    isMarkedLecture(_id) {
      return this.markedId.includes(_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  &-card {
    scroll-margin-top: 135px;
  }
  &__wrapper {
    display: flex;
    padding: 2px 13px;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;

    p {
      margin: 0;
      font-size: 14px;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.14px;
    }

    &.isOpen {
      .button-line {
        position: sticky;
        bottom: 0;
      }
    }
  }
}
.section-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
}
.section-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
}
.section-line {
  display: flex;
  justify-content: space-between;
}
.section-title {
  display: flex;
  justify-content: space-between;
  row-gap: 15px;
  color: #356899;
  // font-size: 14px;
  // line-height: 1.1rem;
  font-style: normal;
}
.section-time, .section-place {
  font-weight: 400;
  color: #356899;
  font-weight: 500;
}
.button-line {
  display: flex;
  flex-wrap: nowrap;
  // justify-content: center;
  margin: 0;
  // padding: 8px 13px 4px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background: #FEFEFE;
  gap: 8px;

  & div[class^='col'] {
    padding: 0;
    flex-shrink: 1;
  }

  & .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 38px;
    font-size: 1.25rem;
    // border-radius: 16px;
    background: #FFFFFF;
  }

  & > div:first-child > .btn {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  & > div:last-child > .btn {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
.section-speaker, .section-moderate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 15px;
  color: #95969D;

  .speaker-item, .moderate-item {
    display: block;
    color: #2c3e50;
    font-size: 14px;
    text-decoration: none;
    outline: none;
    white-space: nowrap;

    &.current {
      color: #356899;
    }
  }
}
.section-lecture {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.btn-more {
  color: #356899;
  border: 2px solid #356899;

  &:active {
    color: #FFFFFF;
    background: #356899;
  }
}
.btn-accent {
  color: #D76535;
  border: 2px solid #D76535;

  &.active, &:active {
    color: #FFFFFF;
    background: #D76535;
  }
}
</style>
