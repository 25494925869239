import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getMap() {
      this.$store.commit('setProgressVal', 1);
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/map/`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => data.results || null)
        .catch(() => {
          this.$store.commit('setProgressVal', 100);
          return false;
        });
    },
  },
};
