<template>
  <div class="programs-list__wrapper">
    <div
      class="program-filter-line"
    >
      <nav-tab
        v-if="optionsDateList.length"
        :tabs="optionsDateList"
        :cur-url="selectedDate"
        @change-day="updateProgramList"
      />
      <drop-list
        v-if="optionsHall.length"
        :options="optionsHall"
        :selected="selectedHall"
        :default-name="$t('programList.default_hall')"
        @update="handleUpdateSelected"
      />
    </div>
    <div
      v-if="!isLoading"
      class="programs-list"
    >
      <up-card
        v-if="!optionsDateList.length && !programList.length"
        class="help-card"
      >
        <div class="help-meesage">{{ $t('programList.no_program') }}</div>
      </up-card>
      <section-card
        v-for="section in programList"
          :id="`s_${section.id}`"
          :key="`s_${section.id}`"
          :section="section"
          :markedId="listMarkedID"
          :hash-to-scroll="isOpenSection.section === `${section.id}` ? urlHash : null"
          :do-scroll-item="isOpenSection.section === `${section.id}` ? isOpenSection : null"
          @mark-favorite="onMarkFavorite"
      />
    </div>
    <pre-loader v-if="isLoading"/>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import global from '@/components/mixins/methodsGlobal';
import appBaseNav from '@/components/mixins/appBaseNav';
import programs from '@/components/mixins/methodsPrograms';
import calendar from '@/components/mixins/methodsCalendar';
import banners from '@/components/mixins/methodsBanners';

export default {
  name: 'ListPrograms',
  props: ['launchDownload', 'locale', 'date'],
  data() {
    return {
      baseProgramList: [],
      programList: [],
      DateList: [],
      hallList: [],
      isLoading: false,
      selectedDate: null,
      selectedHall: null,
      countPage: null,
      apiController: {
        date: null,
        hall: null,
        program: null,
      },

      urlHash: null,
    };
  },
  mixins: [global, appBaseNav, programs, calendar, banners],
  computed: {
    optionsHall() {
      if (this.hallList.length > 0) {
        return this.hallList;
      }
      return [];
    },
    optionsDateList: {
      get() {
        return [...this.DateList].map((itemDay) => {
          // eslint-disable-next-line no-unused-vars
          const [trash, year, month, day, ...rest] = itemDay.match(/^(.{4})(.{2})(.{2})$/);
          return {
            name: DateTime.fromObject({ year, month, day }).setLocale(this.$store.getters.getLang).toFormat('d MMMM'),
            url: itemDay,
          };
        });
      },
      set(_dateList) {
        this.DateList = [..._dateList];
      },
    },
    isOpenSection() {
      const elementLectureId = this.urlHash && this.urlHash.match(/l_\d*/g);
      const elementSectionId = this.urlHash && this.urlHash.match(/s_\d*/g);

      return {
        section: elementSectionId ? elementSectionId[0].replace('s_', '') : null,
        lecture: elementLectureId ? elementLectureId[0].replace('l_', '') : null,
      };
    },
    scrollToItem() {
      return this.urlHash || null;
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('programList.header_title'));
      this.$emit('loading', true);
      this.initProgram();
      // this.$router.go();
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('programList.header_title'));
    this.loadCachedProgram();
    this.loadUserCalendar();
    if (this.programList.length < 1) this.$emit('loading', true);
    this.initProgram();
    if (this.$route.hash) {
      this.urlHash = this.$route.hash.replace('#', '');
    }
  },
  methods: {
    initProgram() {
      this.apiController.date = new AbortController();
      this.apiController.hall = new AbortController();
      Promise.all([
        this.getBanner('program', this.$store.getters.getBannerProgramTs),
        this.getAllDate(this.apiController.date.signal),
        this.getHallList(this.apiController.hall.signal),
      ])
        .then(([
          banner,
          allDate,
          allHalls,
        ]) => {
          if (banner && Object.keys(banner).length) {
            this.$emit('showBanner', banner);
            const nowMilis = DateTime.now().toMillis();
            localStorage.setItem('bannerProgramTs', nowMilis);
            this.$store.commit('setBannerProgramTs', nowMilis);
          }
          if (allDate) {
            this.optionsDateList = allDate;
            this.selectedDate = this.date || allDate[0];
          }
          if (allHalls) this.setHallList(allHalls);
        })
        .finally(() => {
          this.getPage();
          this.$emit('loading', false);
        })
        .catch((error) => {
          console.error('List program error:: ', error);
        });
    },
    setHallList(hallList_) {
      // TODO забыл почему так
      this.hallList = [...hallList_];
    },
    getPage() {
      if (this.programList.length < 1) this.isLoading = true;
      const fixSelectedDate = this.selectedDate;
      this.apiController.program = new AbortController();
      this.getProgramList(this.selectedDate, this.selectedHall, this.apiController.hall.signal)
        .then((data) => {
          this.$emit('turnOff', {
            stateDownload: false,
            stateCheckInbox: false,
          });
          if (data !== null) {
            if (fixSelectedDate === this.selectedDate) {
              this.baseProgramList = [...data.results];
              this.programList = [...data.results];
            }
            this.countPage = data.num_pages;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    sessionDate(i) {
      return this.dateEvent(this.timeStart(this.programList[i].start_ts));
    },
    updateProgramList(newDate) {
      this.selectedDate = newDate;
      this.loadCachedProgram();
      this.getPage();
    },
    handleUpdateSelected(selected) {
      this.selectedHall = selected;
      this.programList = selected === null
        ? this.baseProgramList
        : this.baseProgramList.filter((item) => item.place_id === selected);
      // this.getPage();
    },
    loadCachedProgram() {
      const exhID = this.$store.getters.getExhId;
      const jsonDateList = localStorage.getItem(`exh-${exhID}-day-list`);
      const jsonHallList = JSON.parse(localStorage.getItem(`exh-${exhID}-hall-list`));

      if (jsonDateList) {
        const parseDateList = JSON.parse(jsonDateList);
        this.optionsDateList = (Array.isArray(parseDateList) && parseDateList.length > 0)
          ? parseDateList : [];
      }
      if (jsonHallList) this.setHallList(jsonHallList);
      if (!this.selectedDate && jsonDateList && this.optionsDateList.length > 0) {
        this.selectedDate = this.date || this.optionsDateList[0].url;
      }

      const jsonProgram = localStorage.getItem(`exh-${exhID}-bp-${this.selectedDate}`);
      // TODO не подходит для постраничной загрузки ДП
      if (jsonProgram && jsonProgram !== 'null') {
        const program = JSON.parse(jsonProgram);
        this.baseProgramList = [...program.results];
        this.programList = [...program.results];
      } else {
        this.programList = [];
      }
    },
    onMarkFavorite(data) {
      // console.log(this.userCalendar);
      // console.log('onMarkFavorite', data);
      if (this.listMarkedID.includes(data.id)) {
        this.removeFromUserCalendar(data.id);
        this.sendLogMetrika(this.logMetrika({
          url: this.$route.fullPath,
          action: 'remove from calendar',
          props: JSON.stringify(this.userCalendar),
        }));
      } else {
        this.addToUserCalendar(data);
        this.sendLogMetrika(this.logMetrika({
          url: this.$route.fullPath,
          action: 'add to calendar',
          props: JSON.stringify(this.userCalendar),
        }));
      }
      // console.log(this.$route.fullPath);
    },
  },
  beforeUnmount() {
    if (this.apiController.date) this.apiController.date.abort();
    if (this.apiController.hall) this.apiController.hall.abort();
    if (this.apiController.program) this.apiController.program.abort();
    // this.$store.commit('setProgressVal', 0);
  },
  // unmounted() {
  //   this.$store.commit('setProgressVal', 0);
  //   console.log('unmounted', this.$store.getters.isProgressVal);
  // },
};
</script>

<style lang="scss">
.programs-list {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  &__wrapper {
    position: relative;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    gap: 12px;

    .program-filter-line {
      display: block;
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 100;
      background: #fefefe;
      border-bottom: 2px solid #F6F6FA;
    }
  }

  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 12px 12px;
}
.help-card {
  padding: 10px;
  color: #AFB0B6;

  .help-meesage {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .icon-block {
    display: flex;
    align-items: baseline;
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
    animation: moving-chevron 10s infinite ease-in-out 2s none;
  }
  .text-block {
    font-size: 1rem;
  }

  &__wrapper {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
}
</style>
