<template>
  <div class="about__wrapper">
    <div class="slider__wrapper">
      <div
        v-if="titleImg"
        class="slide"
        :class="{ 'slide-p': titleImpPad }"
      >
        <img :src="titleImg" alt="" srcset="">
      </div>
    </div>
    <div class="container">
      <h1 v-if="title">{{ title }}</h1>
      <p v-if="!formatHtml" class="break-line">
        {{ description }}
      </p>
      <div
        v-else
        v-html="description"
      />
    </div>
  </div>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import about from '@/components/mixins/methodsAbout';

export default {
  name: 'AboutView',
  props: ['locale'],
  data() {
    return {
      titleImg: null,
      titleImpPad: false,
      title: null,
      description: null,
      formatHtml: false,
    };
  },
  mixins: [appBaseNav, about],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('about.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('about.header_title'));
    Promise.all([
      this.getAbout(this.$store.getters.getExhId),
    ])
      .then(([
        aboutData,
      ]) => {
        this.titleImg = aboutData.title_img;
        this.titleImpPad = aboutData.title_img_padding;
        this.title = aboutData.full_name;
        this.description = aboutData.description.trim();
        this.formatHtml = aboutData.description_format === 'html';
      })
      .catch(() => {});
  },
};
</script>
<style lang="scss" scoped>
.about__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
}
.slider {
  &__wrapper {
    .slide {
      &.slide-p {
        padding: 20px;
      }
    }
    & img {
      max-width: 100%;
    }
  }
}
.break-line {
  white-space: break-spaces;
}
</style>
