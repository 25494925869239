import axios from 'axios';
// eslint-disable-next-line import/no-unresolved
// import router from 'router';

export default {
  methods: {
    baseAxios(props, updStatus = true) {
      return axios({
        headers: this.$store.getters.getToken ? { Authorization: `Bearer ${this.$store.getters.getToken}` } : {},
        method: props.method,
        url: props.url,
        data: props.data || ({}),
        timeout: 10000,
        signal: props.signal || null,
        onDownloadProgress: (progressEvent) => {
          if (typeof props.onDownloadProgress === 'function') props.onDownloadProgress(progressEvent);
        },
      })
        .then(({ data }) => {
          if (updStatus) this.$store.commit('updConnectionStatus', true);
          return data;
        })
        // .finally(() => {})
        .catch((error) => {
          if (updStatus && error.code && error.code === 'ERR_NETWORK') {
            this.$store.commit('updConnectionStatus', false);
          }
          if (this.$route.name !== 'loginView') {
            this.$store.commit('setLastUrl', { name: this.$route.name, params: this.$route.params });
          }
          // const { results } = error.response.data ? error.response.data : null;
          if (error.response) {
            const { results } = error.response.data;
            switch (error.response.status) {
              case 301:
                // TODO продумать структуру ответа на редирект
                if (results.redirect.appname === 'static-page') {
                  this.$router.push({ name: 'staticPage', params: { alias: results.redirect.page } });
                }
                break;
              case 401:
                return Promise.resolve({ results: { message: 'Неизвестный App ID' } });
                // break;
              case 403:
                console.log('403', error.response.status);
                this.$store.commit('setToken', null);
                localStorage.removeItem('token');
                this.$router.push({ name: 'loginView' });
                break;
              case 404:
                console.log('to 404 page');
                this.$router.push({ name: 'page404' });
                break;
              case 423:
                console.log('to 423 page');
                localStorage.removeItem('exhID');
                this.$router.push({ name: 'page404' });
                break;
              case 418:
                console.error('Не понимаю... Может лучше выпьем чаю?');
                break;
              default:
                console.log('Promise.reject');
                return Promise.reject(error);
                // return null;
                // break;
            }
          }
          return null;
        });
    },
  },
};
